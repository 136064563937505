import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import Header from '../components/Header';
import AmbassadorsContent from '../components/Programs/AmbassadorsContent';

export const AmbassadorsPageTemplate = ({ title, members, content }) => {
  return (
    <div className="flex flex-col w-full bg-s4tk-white">
      <Header title={title} />
      <AmbassadorsContent members={members} content={content} />
    </div>
  );
};

const AmbassadorsPage = ({ data }) => {
  const members = data.allMarkdownRemark.edges.map(
    (item) => item.node.frontmatter
  );

  const content = data.markdownRemark.frontmatter.content;

  return (
    <Layout>
      <AmbassadorsPageTemplate
        title="S4TK Ambassadors"
        members={members}
        content={content}
      />
    </Layout>
  );
};

export default AmbassadorsPage;

export const ambassadorsPageQuery = graphql`
  query AmbassadorsPageTemplate {
    allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "ambassadors-item" } } }
    ) {
      edges {
        node {
          frontmatter {
            image {
              childImageSharp {
                fluid(maxWidth: 1920, quality: 50) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            name
            facts {
              title
              text
            }
          }
        }
      }
    }
    markdownRemark(
      frontmatter: { templateKey: { eq: "ambassadors-content" } }
    ) {
      frontmatter {
        content {
          blurb
          item {
            title
            icon {
              prefix
              title
            }
            url
          }
        }
      }
    }
  }
`;
